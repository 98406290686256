import React from 'react';
import SocialMediaRow from './components/socialMediaRow';


const Contact = () => {
  return (
    <div className='fullWidth'>
      <div className='home-container'>
        Contact me on: &nbsp; &nbsp; <SocialMediaRow />
      </div>
    </div>
  );
};

export default Contact;
