import React from 'react';
import './components/all.css';
import employmentData from './assets/otherdata/employment.json';
import EmploymentHistory from './components/employment';

const About = () => {
  return (
    <div className='fullWidth'>
      <div className='all-container'>
        <EmploymentHistory employmentData={employmentData} />
      </div>
    </div>
  );
};

export default About;
