import React from 'react';
import './components/all.css';

const Blog = () => {


  return (
    <div className='fullWidth'>
      <div className='home-container'>
      <iframe
      title="substack"
      src="https://nonso2.substack.com/embed"
      width="480"
      height="320"
      style={{ border: '1px solid #EEE', background: 'white' }}
      frameborder="0"
      scrolling="no"
    />
      </div>
    </div>
  );
};

export default Blog;
