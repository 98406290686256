import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <img src="/assets/nlogo.png" alt="Logo" />
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li><NavLink to="/" className="nav-button" end>Home</NavLink></li>
          <li><NavLink to="/about" className="nav-button">Work</NavLink></li>
          <li><NavLink to="/research" className="nav-button">Research</NavLink></li>
          <li><NavLink to="/blog" className="nav-button">Blog</NavLink></li>
          <li><NavLink to="/contact" className="nav-button">Contact</NavLink></li>
        </ul>
        <div className="hamburger" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
