import React from 'react';
import './components/Home.css';
import myPortrait from './assets/portrait.jpg'; // Adjust the path as necessary
import SocialMediaRow from './components/socialMediaRow';

const Home = () => {
  return (
    <div className="fullWidth">
    <div className="home-container">
      <div className="column left-column">
        <h1>Nonso Obikili</h1>
        <h2>Economist + African + Others...</h2>
        <p>Current Position - Programme Specialist
        <br />UNESCO - &nbsp;
        <a href="https://www.unesco.org/en/routes-enslaved-peoples" target="new">
          Routes of Enslaved Peoples: Resistance, Liberty and Heritage Programme
          </a>
        </p>
        <SocialMediaRow />
      </div>
      <div className="column right-column">
      <img 
          src={myPortrait} 
          alt="Nonso Obikili Portrait"
          className="circular-image"
        />
      </div>
      </div>
    </div>
  );
};

export default Home;
