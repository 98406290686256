import React from 'react';
import './all.css';


const EmploymentHistory = ({ employmentData }) => {
  return (
        <div className="employment-history">
        <div className="employment-history-header">
            Employment History
        </div>
      {employmentData.map((job, index) => (
        <div key={index} className="employment-entry">
          <h3>{job.title}</h3>
          <p>
            {job.employer}
            <br />
            {job.location}
            <br />
            {job.start}
            <br />
            {job.end}
            <br />
          </p>
        </div>
      ))}
      </div>
  );
};

export default EmploymentHistory;