import React from 'react';
import './components/all.css';
import researchData from './assets/otherdata/research.json';
import ResearchOutput from './components/research';

const Research = () => {
  return (
    <div className='fullWidth'>
      <div className='all-container'>
        <ResearchOutput researchData={researchData} />
      </div>
    </div>
  );
};

export default Research;
