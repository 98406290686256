import React from 'react';
import './all.css';


const ResearchOutput = ({ researchData }) => {
  return (
        <div className="employment-history">
        <div className="employment-history-header">
            Research Output
        </div>
      {researchData.map((job, index) => (
        <div key={index} className="employment-entry">
          <p>{job.title}</p>
          <p>
          <i>{job.coauthors}</i>
            <br /><br />
            <b>{job.journal}</b>
            
             &nbsp; {job.year}
            <br />
            {job.status}
            <br />
            <button className='fa-button' onClick={() => window.open(job.link)}>
              Read Paper
            </button>
            <br />
          </p>
        </div>
      ))}
      </div>
  );
};

export default ResearchOutput;