// SocialMediaRow.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub, faBlogger, faMastodon } from '@fortawesome/free-brands-svg-icons';

const SocialMediaRow = () => {
  return (
    <div style={styles.row}>
      <a href="https://www.linkedin.com/in/nonso-obikili-8996b310" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
      </a>
      <a href="https://github.com/nonso02" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} style={styles.icon} />
      </a>
      <a href="https://nonso2.substack.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faBlogger} style={styles.icon} />
      </a>
      <a href="https://sciences.social/@nonso2" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faMastodon} style={styles.icon} />
      </a>
      <a href="https://bsky.app/profile/nonso2.bsky.social" target="_blank" rel="noopener noreferrer">
        <img src="/path-to-bluesky-icon.svg" alt="Bluesky" style={styles.icon} />
      </a>
      <a href="https://x.com/nonso2" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
      </a>
    </div>
  );
};

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    margin: '20px 0',
  },
  icon: {
    fontSize: '18px',
    color: '#1859DD',
  },
};

export default SocialMediaRow;